import { render, staticRenderFns } from "./diseaseStart.vue?vue&type=template&id=7e6c351c&scoped=true&"
import script from "./diseaseStart.vue?vue&type=script&lang=js&"
export * from "./diseaseStart.vue?vue&type=script&lang=js&"
import style0 from "./diseaseStart.vue?vue&type=style&index=0&id=7e6c351c&prod&scoped=true&lang=css&"
import style1 from "./diseaseStart.vue?vue&type=style&index=1&id=7e6c351c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e6c351c",
  null
  
)

export default component.exports